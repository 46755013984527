import React, { FC } from "react";
import { ReactComponent as SpinnerDottedIcon } from "../../assets/gifs/spinner.svg";
import "../styles.scss";
import { IButton } from "../model";
import { ButtonState } from "../enum";

const Button: FC<IButton> = (props) => {
  const {
    state,
    text,
    icon,
    extraIcon,
    type,
    bordered,
    css,
    disabled,
    loading,
    ...rest
  } = props;
  return (
    <button
      className={`${
        state === ButtonState.PRIMARY ? "button_primary" : "button_light"
      } gap-x-2 lg:gap-x-4 text-labels lg:text-sm-regular xl:px-8 px-3 py-4 ${
        bordered && "btn_bordered"
      } ${css}`}
      type={type ?? "submit"}
      {...rest}
      disabled={disabled || loading}
    >
      {extraIcon ? (
        <img src={extraIcon} alt={"extraIcon"} className={"h-4"} />
      ) : (
        <></>
      )}
      {text ?? "Button"}
      {icon ? <img src={icon} alt={"icon"} className={"h-4"} /> : <></>}
      {loading ? (
        <span className={"spinner ml-2"}>
          <SpinnerDottedIcon className={"h-4"} />
        </span>
      ) : (
        ""
      )}
    </button>
  );
};

export default Button;
