import React from "react";
import "../styles.scss";
import { Form, Input } from "antd";
import { Formik } from "formik";

const values = {
  email: "",
};

const JoinWaitingList = () => {
  const [form] = Form.useForm();
  const submit = (values: any) => {
    console.log(values);
  };
  return (
    <Formik initialValues={values} onSubmit={submit} enableReinitialize={true}>
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className="md:w-[394px] waiting-list">
            <Form.Item name={"email"}>
              <Input
                type={"email"}
                className={"form-waiting"}
                value={values?.email || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email address"
              />
            </Form.Item>
            <button type="submit">Join Waitlist</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { JoinWaitingList };
