import React, { FC, useEffect, useState } from "react";
import QuicstockLogoWhite from "../../assets/imgs/quicstock-logo-white.png";
import "../styles.scss";
import { NavLink } from "react-router-dom";
import { INavBar } from "../model";

const NavBar: FC<INavBar> = ({ className }) => {
  const [scrolled, setScrolled] = useState(false);

  const handleClickScroll = (e: any, id: string, route: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div className={"fixed w-full z-20"}>
      <nav
        className={`${scrolled ? "nav-bar-floated bg-white" : "bg-transparent"}`}
      >
        <div className={"container"}>
          <div className={"flex items-center justify-between h-20 sm:h-24"}>
            <div className={"flex items-center"}>
              <div className={"flex-shrink-0"}>
                <NavLink
                  to={"/"}
                  onClick={(e) => handleClickScroll(e, "home", "/")}
                >
                  <img className={"lg:h-6 h-4"} src={QuicstockLogoWhite} alt={"Logo"} />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
