import React, { FC } from "react";
import "../styles.scss";
import { IRainbowBorder } from "../model";
import { RainbowBorderState } from "../enum";

const RainbowBorder: FC<IRainbowBorder> = (props) => {
  const { state, text, icon, css, disabled, ...rest } = props;
  return (
    <button
      className={`${
        state === RainbowBorderState.DARK
          ? "dark_rainbow_border"
          : "light_rainbow_border"
      } gap-x-2 lg:gap-x-4 text-sm-regular lg:text-sm-headline xl:px-4 px-3 py-2 ${css}`}
      {...rest}
      type="button"
      disabled={disabled}
    >
      {text ?? "Button"}
      {icon ? <img src={icon} alt={"icon"} className={"h-4"} /> : <></>}
    </button>
  );
};

export default RainbowBorder;
