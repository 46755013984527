import React, { FC, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { IMainLayout } from "../model";

const MainLayout: FC<IMainLayout> = ({ className, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`${className ? className : ""}`}>
      <NavBar className={className} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
